<template>
    <v-main class="main-bg-avbox">
        <div
            class="cookie-notification"
            v-if="cookieShow"
        >
            <p>
                Этот сайт использует cookie и другие технологии для сбора информации технического характера. Подробнее о
                <router-link :to="{name: 'Cookies'}" target="_blank">
                    файлах cookie
                </router-link>
            </p>
            <v-btn
                color="gray"
                class="cookie-accept"
                @click="cookieAccept"
            >
                Хорошо
            </v-btn>
        </div>

        <div class="avbox-main-block">
            <section class="main-section">
              <div class="content">
                <div class="main-desc">
                  <img src="/img/avbox/doctor-main.png" alt="" class="main-bg-doctor-avbox" />
                  <div class="main-text">
                    <h1 class="h1-main">
                      ТВОЙ ЛИЧНЫЙ <br />
                      СЕМЕЙНЫЙ ДОКТОР
                    </h1>
                    <h3 class="h3-main">-Аптечка aloe vera box</h3>
                  </div>
                    
                  <div style="text-align: center;">
                    <button @click="scrollToForm()" class="btn-main-more">Узнать больше</button>
                  </div>
                  <img class="img-main" src="/img/avbox/aptechka-main.png" alt="">
                </div>
                <div class="main-mobile">
                  <div class="main-text">
                    <h1 class="h1-main">
                      ТВОЙ ЛИЧНЫЙ <br />
                      СЕМЕЙНЫЙ ДОКТОР
                    </h1>
                    <h3 class="h3-main">-Аптечка aloe vera box</h3>
                    <img class="img-main" src="/img/avbox/aptechka-main.png" alt="">
                    <div style="text-align: center;">
                      <button @click="scrollToForm()" class="btn-main-more">Узнать больше</button>
                    </div>
                  </div>
                  <div class="block-doctor">
                    <img src="/img/avbox/doctor-main.png" alt="" class="main-bg-doctor-avbox" />                  
                  </div>
                </div>
              </div>
            </section>
            <section class="avbox-section">
              <div class="content">
                <v-row>
                  <v-col cols="12" sm="9">
                    <h2 class="h2-avbox">
                      АПТЕЧКА ALOE VERA "СКОРАЯ ПОМОЩЬ" ОТ LR - ЭТО ЗАЩИТА 24/7 ПРИ:
                    </h2>                
                    <v-row>
                      <v-col cols="12" sm="6">
                        <ul>
                          <li class="li-avbox">царапинах и ушибах</li>
                          <li class="li-avbox">ангине и насморке</li>
                          <li class="li-avbox">укусах насекомых и раздражениях</li>
                          <li class="li-avbox">ожогах бытовых и солнечных</li>
                          <li class="li-avbox">воспалении и боли</li>
                        </ul>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <ul>
                          <li class="li-avbox">проблемах со слизистой</li>
                          <li class="li-avbox">поврежденных волосах</li>
                          <li class="li-avbox">зуде и аллергиях</li>
                          <li class="li-avbox">опрелостях и акне</li>
                          <li class="li-avbox">сухой и ороговевшей коже</li>
                        </ul>
                      </v-col>                
                    </v-row>
                  </v-col>                  
                  <v-col cols="12" sm="3" class="avbox-col-img-mobile">
                    <img src="/img/avbox/aptechka-2.png" alt="" class="avbox-bg-avbox-right" />
                  </v-col>                  
                  <img src="/img/avbox/aptechka-2.png" alt="" class="avbox-bg-avbox-right mobile-img" />
                </v-row>                
                <h3 class="h3-avbox">ALOE VERA BOX - НАУЧНО ДОКАЗАННАЯ ЭФФЕКТИВНОСТЬ И 5000 ЛЕТ ПРАКТИЧЕСКОГО ПРИМЕНЕНИЯ</h3>
                <div class="video-box">
                  <div class="avbox-video">
                    <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/6wu9wcjqks?seo=false&videoFoam=true" title="Фильм о бренде LR AloeVia HD Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                  </div>
                </div>
                <h3 class="h3-avbox doctor-bottom">
                  АПТЕЧКУ ALOE ОТ LR HEALTH & BEAUTY ТАК ЖЕ ВАЖНО ИМЕТЬ ДОМА, КАК ЙОД ИЛИ ПЛАСТЫРЬ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()" class="avbox-btn">Аптечка</button>
                </div>
                <img src="/img/avbox/doctor-right.png" alt="" class="avbox-doctor-bg-right">
              </div>
            </section>
            <section class="emergency-section">
              <div class="content">
                <h2 class="h2-avbox">
                  ПОЗВОЛЬ СЕБЕ САМОЕ ЛУЧШЕЕ ОТ ПРИРОДЫ
                </h2>
                <v-row>
                  <v-col cols="12" sm="4" class="text-center">
                    <div class="emergency-img">
                      <img src="/img/avbox/emergency-spray-border.png" alt="" />
                    </div>
                    <button @click="scrollToEmergency()" class="avbox-emergency-btn">Восстановление</button>
                    <p class="p-avbox mt-7">Aloe Vera Спрей «Скорая помощь»</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center">
                    <div class="emergency-img">
                      <img src="/img/avbox/concentrated-border.png" alt="" />
                    </div>
                    <button @click="scrollToConcentrated()" class="avbox-emergency-btn">Увлажнение</button>
                    <p class="p-avbox mt-7">Aloe Vera Увлажняющий гель-концентрат</p>
                  </v-col>
                  <v-col cols="12" sm="4" class="text-center">
                    <div class="emergency-img">
                      <img src="/img/avbox/propolis-border.png" alt="" />
                    </div>
                    <button @click="scrollToPropolis()" class="avbox-emergency-btn">Защита</button>
                    <p class="p-avbox mt-7">Aloe Vera Защитный крем с прополисом</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="9">
                    <p class="p-avbox p-600-center mt-7">
                      <strong>В составе Aloe Vera Box 2 крема и спрей взаимодополняют и усиливают друг друга</strong>
                    </p>
                    <h4 class="h4-avbox">
                      УНИКАЛЬНАЯ И ЗАПАТЕНТОВАННАЯ КОМБИНАЦИЯ КОНЦЕНТРИРОВАННОГО ЛИСТОВОГО ГЕЛЯ АЛОЭ, ПРОПОЛИСА И ЛЕКАРСТВЕННЫХ ТРАВ
                    </h4>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <img src="/img/avbox/aptechka.png" alt="" class="aptechka-bg-right-bottom">
                  </v-col>
                </v-row>
                <div class="video-box">
                  <div class="avbox-video">
                    <div class="wistia_responsive_padding" style="padding:51.04% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/muf5lk3oo7?seo=false&videoFoam=true" title="Аптечка алое вера1 Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                  </div>
                </div>
                <h2 class="h2-avbox">ТВОЯ ЛИЧНАЯ СКОРАЯ ПОМОЩЬ С МГНОВЕННЫМ ВЫЗОВОМ ЭТО:</h2>
                <v-row>
                  <v-col cols="12" sm="6">
                    <ul>
                      <li class="li-avbox">применение в любом возрасте без противопоказаний</li>
                      <li class="li-avbox">незаменимый товарищ в отпуске и на даче</li>                      
                      <li class="li-avbox">сияющий и здоровый вид твоей кожи</li>
                      <li class="li-avbox">антиоксидантные свойства</li>
                      <li class="li-avbox">питание и наполнение</li>
                      <li class="li-avbox">глубокое увлажнение</li>
                    </ul>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <ul>
                      <li class="li-avbox">противогрибковый и противобактериальный эффект</li>
                      <li class="li-avbox">лучший друг и защитник твоего ребенка</li>
                      <li class="li-avbox">компактность - легко взять в дорогу</li>
                      <li class="li-avbox">замена сотен аптечных средств</li>
                      <li class="li-avbox">помощь в сохранении загара</li>
                      <li class="li-avbox">регенерация и омоложение</li> 
                    </ul>
                  </v-col>
                </v-row>
                <h3 class="h3-avbox">НЕ ЖДИ - ПОЗАБОТЬСЯ О СВОЕЙ КОЖЕ И ЗДОРОВЬЕ УЖЕ СЕЙЧАС С АПТЕЧКОЙ АЛОЕ ВЕРА ОТ КОМПАНИИ <span class="perenos-mobile">LR HEALTH & BEAUTY</span></h3>
                <p class="p-avbox mb-12 text-center">
                  <strong>Комбинация трех ингредиентов аптечки закроет практически все потребности в защите семьи</strong>
                </p>
                <div style="text-align: center;">
                  <button @click="scrollToForm()" class="avbox-btn">Купить</button>
                </div>
              </div>
            </section>
            <section class="doctor-section">
              <div class="content">
                <h2 class="h2-avbox">
                  АЛОЕ ВЕРА - ДОКТОР В ЦВЕТОЧНОМ ГОРШКЕ
                </h2>
                <p class="p-avbox mb-10">
                  <strong>Мы используем самые эффективные природные компоненты: в основе Aloe Vera Box до 90%
                  листового геля Алоэ - это природный эксперт по уходу с яркой историей.</strong>
                </p>
                <v-row class="text-center">
                  <v-col cols="12" sm="3">
                    <div class="flex-col">
                      <img src="/img/avbox/grandmam.png" alt="">
                    </div>
                    <p class="p-avbox doctor-row mt-7">
                      <strong>Наши заботливые бабушки, лечили нас этим растением</strong>
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="flex-col">
                      <img src="/img/avbox/makedonskiy.png" alt="">
                    </div>
                    <p class="p-avbox doctor-row mt-7">
                      <strong>Александр Македонский использовал в качестве зеленой аптечки для солдат</strong>
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="flex-col">
                      <img src="/img/avbox/kleopatra.png" alt="">
                    </div>
                    <p class="p-avbox doctor-row mt-7">
                      <strong>Царица Клеопатра использовала алое как любимое средство для ухода за телом</strong>
                    </p>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <div class="flex-col">
                      <img src="/img/avbox/kolumb.png" alt="">
                    </div>
                    <p class="p-avbox doctor-row mt-7">
                      <strong>Христофор Колумб использовал алое для своих моряков</strong>
                    </p>
                  </v-col>
                </v-row>
                <p class="p-avbox mt-10">
                  <strong>
                    Секрет алое в составе его листового геля: он похож по своей структуре на кожу, поэтому ингредиенты растения легко «вписываются» в текстуру кожи.
                    Листовой гель поддерживает естественные процессы регенерации и обеспечивает эластичность, упругость, гладкость и защиту.
                  </strong>
                </p>
                <p class="p-avbox mt-10">
                  <strong>
                    Алоэ работает на клеточном уровне, проходя внутрь клеток и доставляя все самое необходимое, снабжает клетки кислородом за счет
                    восстановления просвета капилляров.
                  </strong>
                </p>
                <div style="text-align: center;" class="mt-10 mb-5">
                  <img src="/img/avbox/doctor-aptechka.png" alt="" class="pic-avbox">
                </div>
                <p class="p-avbox text-center">
                  <strong>
                    Аптечка Алое от LR - это эволюция бабушкиного горшочка,<br />
                    не требующего полива и земли.
                  </strong>
                </p>
                <h3 class="h3-avbox">
                  ОБРЕТИ ЗДОРОВЬЕ, ВДОХНОВЛЕННОЕ САМОЙ ПРИРОДОЙ И ИСТОРИЕЙ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()" class="avbox-btn">Здоровье</button>
                </div>
              </div>
            </section>
            <section class="emergency-spray-section" id="emergency-spray">
              <div class="content">
                <h3 class="h3-avbox">
                  АЛОЭ ВЕРА СПРЕЙ "СКОРАЯ ПОМОЩЬ" - ОН ТАКОЙ ОДИН
                </h3>
                <v-row class="spray-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/avbox/spray.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="10">
                    <p class="p-avbox mb-5">
                      Благодаря 83% Алоэ Вера спрей интенсивно увлажняет поврежденную кожу. Эффективная комбинация экстрактов из 11 традиционных
                      лекарственных трав и прополиса способствует естественному восстановлению.
                    </p>
                    <p class="p-avbox mb-5">
                      Спрей мягко обволакивает кожу защитной пленкой, питает и успокаивает. Поддерживает регенерацию клеток кожи и восстанавливает ее природный баланс.
                    </p>
                    <p class="p-avbox">
                      Состав лечебных трав: огуречник, ромашка, эвкалипт, имбирь, страстоцвет, шалфей, тимьян, тысячелистник, календула, одуванчик, сандаловое дерево
                    </p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <p class="p-avbox">
                      <strong>Спрей используется при повреждениях и заболеваниях кожи:</strong>
                    </p>
                    <ul>
                      <li class="li-avbox">экзема</li>
                      <li class="li-avbox">псориаз</li>
                      <li class="li-avbox">опоясывающий лишай</li>
                      <li class="li-avbox">красная потница</li>
                    </ul>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p class="p-avbox">
                      <strong>Дает прекрасные результаты при лечении простудных заболеваний:</strong>
                    </p>
                    <ul>
                      <li class="li-avbox">ангина</li>
                      <li class="li-avbox">насморк</li>
                      <li class="li-avbox">бронхит</li>
                      <li class="li-avbox">ларингит</li>
                      <li class="li-avbox">трахеит</li>
                    </ul>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="6">
                    <p class="p-avbox">
                      <strong>Эффективен при женских заболеваниях:</strong>
                    </p>
                    <ul>
                      <li class="li-avbox">эрозии</li>
                      <li class="li-avbox">воспалительных процессах</li>
                    </ul>
                    <p class="p-avbox mt-5">Помогает восстановлению волос и укрепляет корни.</p>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <p class="p-avbox">
                      <strong>Отличное средство при:</strong>
                    </p>
                    <ul>
                      <li class="li-avbox">ожогах</li>
                      <li class="li-avbox">сыпи</li>
                      <li class="li-avbox">аллергиях</li>
                      <li class="li-avbox">укусах насекомых</li>
                      <li class="li-avbox">детской опрелости и ветрянке</li>
                    </ul>
                  </v-col>
                </v-row>
                <h3 class="h3-avbox">
                  ALOE VERA СПРЕЙ «СКОРАЯ ПОМОЩЬ» – ТВОЙ НАДЕЖНЫЙ ПОМОЩНИК И УНИВЕРСАЛЬНОЕ СРЕДСТВО
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()" class="avbox-btn">Помощник</button>
                </div>
                <h3 class="h3-avbox-green">
                  ГДЕ БЫ ТЫ НЕ НАХОДИЛСЯ, ЧТОБЫ НЕ СЛУЧИЛОСЬ - ОН ВСЕГДА ПОМОЖЕТ
                </h3>
              </div>
            </section>
            <section class="concentrated-section" id="concentrated">
              <div class="content">
                <h3 class="h3-avbox">
                  ГЕЛЬ КОНЦЕНТРАТ АЛОЭ - ЛУЧШЕЕ ДЛЯ НЕПРЕВЗОЙДЕННОЙ СВЕЖЕСТИ
                </h3>
                <v-row class="concentrated-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/avbox/concentrated.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="10">
                    <p class="p-avbox mb-5">
                      Содержит 90 % чистого Алоэ Вера. Подходит для любых участков и типов кожи. Глубоко увлажняет, и обогащает всеми нужными элементами.
                    </p>
                    <v-row>
                      <v-col cols="12" sm="6" >
                        <p class="p-avbox">
                          <strong>Он прекрасно снимает:</strong>
                        </p>
                        <ul>
                          <li class="li-avbox">раздражение</li>
                          <li class="li-avbox">зуд</li>
                          <li class="li-avbox">отёчность</li>
                          <li class="li-avbox">воспаление</li>
                          <li class="li-avbox">саднящую боль</li>
                          <li class="li-avbox">быстро заживляет при бытовых и и солнечных ожогах</li>
                        </ul>
                      </v-col>
                      <v-col cols="12" sm="6" >
                        <p class="p-avbox">
                          <strong>Особенно эффективен при:</strong>
                        </p>
                        <ul>
                          <li class="li-avbox">различных дерматозах</li>
                          <li class="li-avbox">острой травме кожного покрова</li>
                          <li class="li-avbox">сильно усушенных и ороговевших участков</li>
                        </ul>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <div class="mt-7 mb-7">
                  <p class="p-avbox">
                    <strong>Применяется при лечении воспалительных процессов женской половой сферы:</strong>
                  </p>
                    <ul>
                      <li class="li-avbox">эрозии</li>
                      <li class="li-avbox">воспаления</li>
                      <li class="li-avbox">кандидоз</li>
                      <li class="li-avbox">нарушения микрофлоры</li>
                    </ul>
                </div>
                <p class="p-avbox mb-5">Концентрат замечательно заживляет опрелости на теле у самых маленьких, помогает при кожных раздражениях и диатезе.</p>
                <p class="p-avbox mb-5">Используется в качестве дополнительного интенсивного увлажнения лица, маски в комплексе уходовых косметических средств.</p>
                <p class="p-avbox">Предотвращает возникновение шрамов и обладает мгновенным обезболивающим эффектом.</p>
                <h3 class="h3-avbox">
                  ЗАБОТА С ЛЮБОВЬЮ - ПОДАРИ СЕБЕ ПРИРОДНЫЙ ЭЛИКСИР ЗДОРОВЬЯ И ДОЛГОЛЕТИЯ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()" class="avbox-btn">Забота</button>
                </div>
              </div>
            </section>
            <section class="propolis-section" id="propolis">
              <div class="content">
                <h3 class="h3-avbox">
                  КРЕМ ПРОПОЛИС - ЗАЩИТНАЯ СИЛА И УХОД ИЗ УЛЬЯ
                </h3>
                <v-row class="spray-item">
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/avbox/propolis.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="10">
                    <p class="p-avbox mb-5">
                      В составе крема 79% Алоэ Вера гель, прополис, травяные экстракты ромашки и окопника, витамин Е.
                    </p>
                    <p class="p-avbox mb-5">
                      Данный крем является одним из лучших препаратов для упругости и регенерации обезвоженной, пострадавшей и огрубевшей кожи.
                    </p>
                    <p class="p-avbox">
                      Улей – настоящий кладезь натуральных питательных веществ. Одним из этих активных ингредиентов является прополис, которым пчелы выстилают свой улей, чтобы защитить его от микробов и вирусов.
                    </p>
                  </v-col>
                </v-row>
                <p class="p-avbox mt-7 mb-7">Гель листьев Алоэ Вера обеспечивает интенсивное увлажнение и успокоит раздраженные, сухие участки кожи.</p>
                <p class="p-avbox mb-7">Прополис придаст вашей коже гладкость, мягко обволакивая ее защитной пленкой, которая укрепит естественный кожный барьер и защитит от потери влаги.</p>
                <p class="p-avbox mb-7">Ромашка и окопник окажут противовоспалительное, вяжущее, заживляющее и успокаивающее действие.</p>
                <p class="p-avbox">Крем отличная защита для холодного времени года и хорошее средство для массажа. Противовоспалительное, антибактериальное и заживляющие действие.</p>
                <h3 class="h3-avbox">
                  ОЩУТИ НА СЕБЕ МОЩЬ ЗАЩИТНЫХ СВОЙСТВ ПРИРОДЫ
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()" class="avbox-btn">Защита</button>
                </div>
              </div>
            </section>
            <section class="aloe-section">
              <div class="content">
                <h3 class="h3-avbox">
                  АЛОЕ МНОГО - ALOE VIA LR HEALTH & BEAUTY ЕДИНСТВЕННЫЙ В СВОЕМ РОДЕ
                </h3>
                <p class="p-avbox mb-5">Твой личный профессиональный специалист по уходу за кожей – с головы до ног, в любом возрасте.</p>
                <p class="p-avbox">28 продуктов серии LR ALOE VIA запатентованы благодаря био-экстрактам и уникальному комплексу биологически активных веществ в составе геля Алоэ Вера.</p>
                <div class="video-box">
                  <div class="avbox-video">
                    <div class="wistia_responsive_padding" style="padding:41.88% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/52rhe971ld?seo=false&videoFoam=true" title="Алоэ Вера 20 лет Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                  </div>
                </div>
                <v-row class="mt-5">
                  <v-col cols="12" sm="2">
                    <div class="flex-col">
                      <img src="/img/avbox/dermatest.png" alt="">
                    </div>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <p class="p-avbox mb-5">
                      Безопасность продуктов Алоэ Вера LR для кожи подтверждена известными независимыми институтами, такими как НИИ Dermatest и Derma Consult и одобрена дерматологами.
                    </p>
                  </v-col>
                </v-row>
                <v-row class="mt-5">
                  <v-col cols="12" sm="2">
                    <div class="flex-col">
                      <img src="/img/avbox/iasc.png" alt="">
                    </div>
                  </v-col>
                  <v-col cols="12" sm="10">
                    <p class="p-avbox mb-5">
                      Наш гель Алоэ Вера регулярно проверяется уважаемым Международным научным советом по Алоэ Вера (IASC), от выращивания до переработки
                    </p>
                    <ul>
                      <li class="li-avbox">уникальная комбинация био экстрактов</li>
                      <li class="li-avbox">без содержания парабенов и минеральных масел</li>
                      <li class="li-avbox">успешный опыт 35 лет</li>
                      <li class="li-avbox">постоянная работа над улучшениями и инновациями</li>
                      <li class="li-avbox">довольные клиенты более чем в 30 странах мира</li>
                      <li class="li-avbox">свой научный совет</li>
                      <li class="li-avbox">лидер продаж продуктов алое в Европе</li>
                      <li class="li-avbox">передовые технологии и экологичное сырье</li>
                      <li class="li-avbox">широкая продуктовая линейка</li>
                      <li class="li-avbox" style="position: relative;">
                        сделано в Германии
                        <img src="/img/avbox/logo-germany.png" alt="" class="germany-avbox-li">
                      </li>
                    </ul>
                  </v-col>
                </v-row>
              </div>
            </section>
            <section class="reviews-section">
              <div class="content">
                <h3 class="h3-avbox">
                  ТЫСЯЧИ ПОЛОЖИТЕЛЬНЫХ ОТЗЫВОВ И ЗНАНИЯ ПО ПРАКТИЧЕСКОМУ ПРИМЕНЕНИЮ ПРОДУКТОВ ИЗ ТВОЕЙ АПТЕЧКИ АЛОЕ
                </h3>
                <v-carousel
                  class="avbox-carousel"
                  cycle
                  height="400"
                  hide-delimiter-background
                  hide-delimiters
                  show-arrows-on-hover
                >
                  <v-carousel-item
                      v-for="(slide, i) in slides"
                      :key="i"
                  >
                    <v-sheet
                      height="100%"
                    >
                      <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="text-h2">
                          <p style="width: 100%; max-width: 450px; height: auto">
                              <img style="width: 100%;" :src=slide.src alt=""/>
                          </p>
                        </div>
                      </v-row>
                    </v-sheet>
                  </v-carousel-item>
                </v-carousel>
                <h3 class="h3-avbox">
                  ДЛЯ ЛЮБИТЕЛЕЙ НАТУРАЛЬНОГО И НАСТОЯЩЕГО!!!
                </h3>
                <div style="text-align: center;">
                  <button @click="scrollToForm()" class="avbox-btn">Натуральное</button>
                </div>
              </div>
            </section>
            <section class="znakomstvo-section">
              <div class="content">
              <h3 class="h3-avbox">
                РАСШИРЬ СВОЕ ЗНАКОМСТВО С ДАРАМИ ПРИРОДЫ НА ВСЕ СЛУЧАИ ЖИЗНИ
              </h3>
              <div class="video-box">
                <div class="avbox-video">
                  <div class="wistia_responsive_padding" style="padding:56.25% 0 0 0;position:relative;"><div class="wistia_responsive_wrapper" style="height:100%;left:0;position:absolute;top:0;width:100%;"><iframe src="https://fast.wistia.net/embed/iframe/hncs2xwtnt?seo=true&videoFoam=true" title="Видео Алое Виа Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" msallowfullscreen width="100%" height="100%"></iframe></div></div>
                </div>
              </div>
              <v-row class="mt-10">
                <v-col cols="12" sm="2" class="flex-col">
                  <img src="/img/avbox/soap.png" alt="">
                </v-col>
                <v-col cols="12" sm="4">
                  <p class="p-title">Крем Мыло Алоэ для рук</p>
                  <p class="p-avbox">
                    Увлажнение и питание для мягкой и нежной кожи рук. Защищает от негативного влияния жесткой и хлорированной воды.
                    Стойкий аромат и богатый состав, способствует сохранению влаги. Бережно очищает и подходит для всей семьи.
                  </p>
                </v-col>
                <v-col cols="12" sm="2" class="flex-col">
                  <img src="/img/avbox/scrab.png" alt="">
                </v-col>
                <v-col cols="12" sm="4">
                  <p class="p-title">Скраб для лица</p>
                  <p class="p-avbox">
                    Эффективно удаляет с поверхности ороговевшие клетки, очищая поры и оздоравливает кожу. Придает сияющий вид и особенную нежность.
                    Улучшает регенерацию клеток и способствует быстрому обновлению.
                  </p>
                </v-col>
              </v-row>              
              <v-row>
                <v-col cols="12" sm="2" class="flex-col mobile-col-left">
                  <img src="/img/avbox/day-cream.png" alt="">
                </v-col>
                <v-col cols="12" sm="4">
                  <p class="p-title">Активизирующий дневной крем</p>
                  <p class="p-avbox">
                    Идеальный уход для сухой кожи лица. Обладает нежной и легкой текстурой, быстро впитывается. Отличная увлажняющая основа под макияж.
                  </p>
                </v-col>
                <v-col cols="12" sm="2" class="flex-col mobile-col-right">
                  <img src="/img/avbox/day-cream.png" alt="">
                </v-col>
                  <v-col cols="12" sm="2" class="flex-col mobile-col-left">
                    <img src="/img/avbox/gel-cream.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="p-title">Освежающий гель крем</p>
                    <p class="p-avbox">
                      Создает эффект мгновенной свежести. Подходит для всех типов кожи. Обеспечивает продолжительное увлажнение сухой и очень сухой кожи.
                      Быстро впитывается благодаря легкой текстуре.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="2" class="flex-col mobile-col-right">
                    <img src="/img/avbox/gel-cream.png" alt="">
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/avbox/thermo.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="p-title">Расслабляющий Термолосьон</p>
                    <p class="p-avbox">
                      Приятный согревающий эффект. Улучшает кровообращение и снимает боль. Подходит для расслабляющего массажа и разогрева перед тренировками.
                    </p>
                  </v-col>
                  <v-col cols="12" sm="2" class="flex-col">
                    <img src="/img/avbox/tooth.png" alt="">
                  </v-col>
                  <v-col cols="12" sm="4">
                    <p class="p-title">Зубная паста-гель</p>
                    <p class="p-avbox">
                      Тщательное и бережное очищение зубов. Лучшее средство для здоровья десен. Сохраняет ощущение свежести на весь день.
                      Имеет приятный и мягкий вкус, подходит для всей семьи.
                    </p>
                  </v-col>
                </v-row>
                <h4 class="h4-avbox">
                  а также:
                </h4>
                <v-row>
                  <v-col cols="12" sm="4">
                    <div class="flex-col">
                      <img src="/img/avbox/baby-set.png" alt="">
                    </div>
                    <p class="p-title-col">Уход за ребенком</p>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="flex-col">
                      <img src="/img/avbox/man-set.png" alt="">
                    </div>
                    <p class="p-title-col">Уход за мужской кожей</p>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="flex-col">
                      <img src="/img/avbox/body-set.png" alt="">
                    </div>
                    <p class="p-title-col">Уход за телом</p>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" sm="4">
                    <div class="flex-col">
                      <img src="/img/avbox/face-set.png" alt="">
                    </div>
                    <p class="p-title-col">Уход за лицом</p>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="flex-col">
                      <img src="/img/avbox/sun-set.png" alt="">
                    </div>
                    <p class="p-title-col">Защита от солнца</p>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <div class="flex-col">
                      <img src="/img/avbox/hair-set.png" alt="">
                    </div>
                    <p class="p-title-col">Уход за волосами</p>
                  </v-col>
                </v-row>
                <h4 class="h4-avbox">
                  ПОПРОБУЙ УНИКАЛЬНЫЕ ПРОДУКТЫ НА ОСНОВЕ АЛОЭ ВЕРА ОТ LR, ПОЧУВСТВУЙ ЗАЩИТУ И НЕВЕРОЯТНОЕ ОМОЛАЖИВАЮЩЕЕ ДЕЙСТВИЕ ИСПЫТАЙ ВОСХИЩЕНИЕ ОТ РЕЗУЛЬТАТА
                </h4>
                <div style="text-align: center;">
                  <button @click="scrollToForm()" class="avbox-btn">Результат</button>
                </div>
              </div>
            </section>
            <section v-if="userData.id != 127" class="form-section" id="avbox-form">
              <div class="content">
                <v-row>
                  <v-col cols="12" sm="6">
                    <p class="p-avbox-form">
                      Оставь заявку и получи бесплатную консультацию по эффективному использованию Аптечки Aloe Vera Box, а также помощь в подборе продукции компании LR необходимой именно тебе!
                    </p>
                    <v-responsive
                        class="mx-auto mb-10"
                        max-width="600px"
                    >
                        <v-text-field
                            label="Ваше имя"
                            prepend-icon="mdi-face-man"
                            type="text"
                            v-model.trim="newProspect.name"
                            :error-messages="nameErrors"
                            @input="$v.newProspect.name.$touch()"
                            @blur="$v.newProspect.name.$touch()"
                        />
                        <v-text-field
                            class="cell-input-phone"
                            label="Телефон"
                            prepend-icon="mdi-phone"
                            type="text"
                            v-model.trim="newProspect.phone"
                            :error-messages="phoneErrors"
                            @input="$v.newProspect.phone.$touch()"
                            @blur="$v.newProspect.phone.$touch()"
                        />
                        <div class="invalid-feedback" v-if="errors.phone">
                            {{ errors.phone[0] }}
                        </div>

                        <p style="text-align: center">
                            <button
                                class="mr-0 avbox-btn"
                                @click="addProspectAvBox"
                            >
                                Отправить
                            </button>
                        </p>
                        <p class="avbox-privacy-text">
                            Оставляя заявку, вы даете свое
                            <router-link :to="{name: 'Personal'}" target="_blank">Согласие на обработку персональных данных</router-link>
                            и принимаете
                            <router-link :to="{name: 'Policy'}" target="_blank">Политику конфиденциальности</router-link>.
                        </p>
                    </v-responsive>
                  </v-col>
                  <v-col cols="12" sm="6" class="flex-col">
                    <img src="/img/avbox/doctor-form.png" alt="" />
                  </v-col>
                </v-row>
                <h3 class="h3-avbox">
                  ОТКРОЙ ДЛЯ СЕБЯ СЕКРЕТ ЗДОРОВЬЯ И КРАСОТЫ С ПРОДУКЦИЕЙ НА ОСНОВЕ АЛОЭ ВЕРА ОТ КОМПАНИИ <span>LR HEALTH & BEAUTY!</span>
                </h3>   
              </div>           
            </section>
            <v-footer
                class="justify-center avbox-footer"
                height="100"
            >
                <div class="title font-weight-light text-center">
                    &copy; {{ (new Date()).getFullYear() }} — newlvl.net — независимый партнер LR Health & Beauty
                </div>
            </v-footer>
        </div>
        <v-dialog
            v-model="dialog_prospect"
            width="500"
        >
            <v-card>
                <v-card-title class="headline lighten-2 pb-5" style="background-color: #213e30; color: white">
                    Заявка принята
                </v-card-title>

                <v-card-text>
                    Твой консультант свяжется с тобой в ближайшее время
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="#213e30"
                        text
                        @click="dialog_prospect = false"
                    >
                        Хорошо
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {maxLength, minLength, numeric, required} from "vuelidate/lib/validators";

export default {
    name: "AloeVeraBox",
    data: () => ({
        newProspect: {
            name: '',
            phone: '',
            partner: '',
            instrument: 'AloeVera Box',
            step: 'Новый'
        },
        slides: [
            {
                src: '/img/avbox/otzivi/1.png',
            },
            {
                src: '/img/avbox/otzivi/2.png',
            },
            {
                src: '/img/avbox/otzivi/3.png',
            },
            {
                src: '/img/avbox/otzivi/4.png',
            },
            {
                src: '/img/avbox/otzivi/5.png',
            },
            {
                src: '/img/avbox/otzivi/6.png',
            },
            {
                src: '/img/avbox/otzivi/7.png',
            },
            {
                src: '/img/avbox/otzivi/8.png',
            },
            {
                src: '/img/avbox/otzivi/9.png',
            },
            {
                src: '/img/avbox/otzivi/10.png',
            },
            {
                src: '/img/avbox/otzivi/11.png',
            },
            {
                src: '/img/avbox/otzivi/12.png',
            },
            {
                src: '/img/avbox/otzivi/13.png',
            },
            {
                src: '/img/avbox/otzivi/14.png',
            },
            {
                src: '/img/avbox/otzivi/15.png',
            },
            {
                src: '/img/avbox/otzivi/16.png',
            },
            {
                src: '/img/avbox/otzivi/17.png',
            },
            {
                src: '/img/avbox/otzivi/18.png',
            },
            {
                src: '/img/avbox/otzivi/19.png',
            },
            {
                src: '/img/avbox/otzivi/20.png',
            },
            {
                src: '/img/avbox/otzivi/21.png',
            },
            {
                src: '/img/avbox/otzivi/22.png',
            },
            {
                src: '/img/avbox/otzivi/23.png',
            },
            {
                src: '/img/avbox/otzivi/24.png',
            },
            {
                src: '/img/avbox/otzivi/25.png',
            },
            {
                src: '/img/avbox/otzivi/26.png',
            },
            {
                src: '/img/avbox/otzivi/27.png',
            },
            {
                src: '/img/avbox/otzivi/28.png',
            },
        ],
        errors: {},
        dialog_main: false,
        dialog_prospect: false,
        loading: false,
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Аптечка Aloe Vera Box от LR',
        meta: [
            { vmid: 'description', property: 'description', content: 'Аптечка Aloe Vera Box от LR' },
            { vmid: 'og:title', property: 'og:title', content: 'Аптечка Aloe Vera Box от LR' },
            { vmid: 'og:description', property: 'og:description', content: 'Аптечка Aloe Vera Box от LR.' },
            { vmid: 'og:image', property: 'og:image', content: '/img/aloe/aloe-vera-box.png' },
        ],
    },
    created() {
        if (!this.$route.query.partner) {
            if (this.$cookies.isKey('newlvl_partner')) {
                const partnerNum = this.$cookies.get('newlvl_partner')
                this.$router.push ({name: 'AvBox', query: { partner: partnerNum }})
            }
        } else {
            this.$cookies.set('newlvl_partner', this.$route.query.partner, '6m')
        }

        if (this.$cookies.isKey('newlvl_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getUser'
        }),
        dataUser() {
            return this.userData
        },
        nameErrors() {
            const errors = []
            if (!this.$v.newProspect.name.$dirty) return errors
            !this.$v.newProspect.name.minLength && errors.push('Минимум 2 буквы')
            !this.$v.newProspect.name.maxLength && errors.push('Максимум 30 букв')
            !this.$v.newProspect.name.required && errors.push('Обязательно для заполнения')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.newProspect.phone.$dirty) return errors
            !this.$v.newProspect.phone.numeric && errors.push('Только цифры')
            !this.$v.newProspect.phone.minLength && errors.push('Минимум 5 цифр')
            !this.$v.newProspect.phone.maxLength && errors.push('Максимум 30 цифр')
            !this.$v.newProspect.phone.required && errors.push('Обязательно для заполнения')
            return errors
        }
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showUser: 'user/showUserPage',
            createProspect: 'user/addClient'
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('newlvl_cookie', 'accept', '12m')
        },
        scrollToEmergency() {
            let scrollBottom = document.getElementById("emergency-spray")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        scrollToConcentrated() {
            let scrollBottom = document.getElementById("concentrated")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        scrollToPropolis() {
            let scrollBottom = document.getElementById("propolis")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        scrollToForm() {
            let scrollBottom = document.getElementById("avbox-form")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
        addProspectAvBox() {
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading = true
                this.createProspect(this.newProspect)
                    .then((response) => {
                        if (response.data && response.data.success) {
                            this.errors = {}
                            this.loading = false
                            this.dialog_prospect = true
                        }
                    })
                    .catch((error) => {
                        switch (error.response.status) {
                            case 422:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            case 500:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                            default:
                                this.loading = false
                                this.addNotification({
                                    show: true,
                                    text: error.response.data.message,
                                    color: 'error'
                                })
                                this.errors = error.response.data.errors
                                break
                        }
                    })
            } else {
                this.addNotification({
                    show: true,
                    text: 'Ошибка валидации',
                })
            }
        },
    },
    mounted () {
        this.showUser({user: this.$route.query.partner, inst: this.$route.query.inst})
        this.newProspect.partner = this.$route.query.partner
        this.newProspect.inst = this.$route.query.inst
    },
    validations: {
        newProspect: {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(30)
            },
            phone: {
                required,
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(30)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: CormorantGaramond;
  src: url('/fonts/CormorantGaramond-Regular.ttf');  
}
.main-bg-avbox {
  font-family: CormorantGaramond;
  color: #000;
  background-color: #EFF4ED;
  font-size: 16px;
}
h1, h2, h3, h4 {
  text-transform: uppercase;
  // letter-spacing: 3px;
  font-weight: 600;
}
.avbox-main-block {  
  overflow-x: hidden;
}
section .content {
  max-width: 1280px;
  position: relative;
  margin: 0 auto;
  padding-right: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-left: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  height: 100%;
}
.main-section {
  background: url("/img/avbox/bg-main.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  height: 90vh;
}
.main-bg-doctor-avbox {
  max-width: 360px;
  width: calc(150px + (360 - 150) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: 0;
  left: 0;
}
.video-box {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
  z-index: 1;
}
.avbox-section,
.emergency-section,
.doctor-section,
.emergency-spray-section,
.concentrated-section,
.propolis-section,
.aloe-section,
.znakomstvo-section,
.reviews-section {
  position: relative;
  z-index: 1;
  padding-bottom: 50px;
}
.emergency-section,
.emergency-spray-section,
.propolis-section,
.form-section {
  background-color: #fff;
}
.main-text {
  text-align: center;
  position: absolute;
  right: 20px;
  padding-top: calc(40px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  // padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.main-mobile {
  display: none;
}
.h1-main {
  font-size: calc(28px + (80 - 28) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;  
  padding-bottom: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
}
.h3-main {
  font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;  
}
.img-main {
  position: absolute;
  bottom: 5%;
  right: 0%;
  width: calc(200px + (350 - 200) * ((100vw - 320px) / (1280 - 320)));
}
.btn-main-more {
  font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 700;
  text-transform: uppercase;
  position: absolute;
  bottom: 15%;
  right: 40%;
  border: 3px solid #000;
  border-radius: 10px;
  padding: 15px 30px;
  letter-spacing: 3px;
}
.avbox-bg-avbox-right {
  width: 100%;
  padding-top: 40px;
}
.avbox-bg-avbox-right.mobile-img {
  display: none;
}
.aptechka-bg-right-bottom {
  width: 100%;
}
.avbox-doctor-bg-right {
  max-width: 250px;
  width: calc(100px + (250 - 150) * ((100vw - 320px) / (1280 - 320)));
  position: absolute;
  bottom: -50px;
  right: 0;
  z-index: -1;
}
.h2-avbox {
  font-size: calc(18px + (46 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
  line-height: 110%;
  padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.avbox-emergency-btn {
  font-size: calc(14px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-transform: uppercase;
  font-weight: 700;
  border: 2px solid #222;
  border-radius: 5px;
  letter-spacing: 2px;
  padding: 7px 15px;
  width: 100%;
} 
.perenos-desc {
  display: block;
}
.germany-avbox-li {
  width: 40px;
  position: absolute;
  margin-left: 10px;
}
.emergency-img {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.emergency-img img {
  width: 100%;
}
.mobile-col-left {
  display: none !important;
}
.h3-avbox {
  font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
  line-height: 110%;
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
}
.h3-avbox.doctor-bottom {
  width: 80%;
  margin: 0 auto;
  font-size: calc(18px + (36 - 18) * ((100vw - 320px) / (1280 - 320)));
}
.h3-avbox-green {
  font-size: calc(18px + (40 - 18) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
  line-height: 110%;
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: 0;
  color: #345b35;
}
.h2-avbox span, .h3-avbox span {
  display: block;
}
.avbox-carousel .theme--light.v-sheet {
  background-color: #EFF4ED;
}
.p-avbox, .li-avbox {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
  margin-bottom: 10px;
}
.p-avbox-form {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
  margin-bottom: 10px;
  text-align: center;
  padding-top: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 700;
  line-height: 120%;
}
.li-avbox { 
  margin-bottom: 10px;
  line-height: 100%;
  margin-left: 20px;
}
.h4-avbox {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  padding-top: calc(10px + (20 - 10) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(20px + (30 - 20) * ((100vw - 320px) / (1280 - 320)));
  text-align: center;
}
.p-title {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  font-weight: 600;
  margin-bottom: 10px;
  padding-top: calc(20px + (40 - 20) * ((100vw - 320px) / (1280 - 320)));
  padding-bottom: calc(15px + (20 - 15) * ((100vw - 320px) / (1280 - 320)));
}
.p-title-col {
  font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (1280 - 320)));
  line-height: 110%;
  font-weight: 600;
  padding-top: 20px;
  text-align: center;
}
.avbox-btn {
  font-size: calc(18px + (24 - 18) * ((100vw - 320px) / (1280 - 320)));
  font-weight: 700;
  color: #345b35;
  text-transform: uppercase;
  border: 3px solid #345b35;
  border-radius: 10px;
  padding: 8px 30px;
  letter-spacing: 3px;
}
.flex-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flex-col img {
  width: 100%;
}
.avbox-footer  {
  background-color: #ECF1E8;  
}
.pic-avbox {
  max-width: 700px;
  width: calc(300px + (700 - 300) * ((100vw - 320px) / (1280 - 320)));
}

@media (min-width: 1280px) {
  .h1-main {
    font-size: 80px;
  }
  .btn-main-more, .avbox-btn, .avbox-emergency-btn {
    font-size: 24px;
  }
  .h2-avbox {
    font-size: 46px;
  }
  .h3-avbox, .h3-avbox-green {
    font-size: 40px;
  }
  .h3-avbox.doctor-bottom {
    font-size: 36px;
  }
  .p-avbox, .li-avbox, .p-avbox-form {
    font-size: 28px;
  }
  .h4-avbox {
    font-size: 28px;
  }
  .p-title, .p-title-col {
    font-size: 28px;
  }
}
@media(max-width: 1000px) {
  .btn-main-more {
    right: 40%;
  }
}
@media(max-width: 857px) {
  .perenos-desc {
    display: inline !important;
  }
}
@media(max-width: 800px) {
  .btn-main-more {
    right: 17%;
    bottom: 7%;
  }
  .avbox-emergency-btn {
    letter-spacing: 0.5px;
  }
  .img-main {
    width: 220px;
    right: 20%;
    bottom: 25%;
  }
  .main-text {    
    padding-top: 20px;
  }
}
@media(max-width: 732px) {
  .btn-main-more {
    right: 15%;
  }
  .avbox-emergency-btn {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media(max-width: 600px) {
  .flex-col img, .emergency-img img {
    width: auto;
  }
  .mobile-col-left {
    display: flex;
  }
  .mobile-col-right {
    display: none;
  }
  .btn-main-more {
    right: 20%;
    padding: 10px;
  }
  .avbox-col-img-mobile {
    display: none;
  }
  .avbox-bg-avbox-right.mobile-img {
    display: block;
    width: calc(100px + (300 - 100) * ((100vw - 320px) / (1280 - 320)));
    position: absolute;
    right: 20px;
    top: 80px;
  }
  .avbox-emergency-btn {
    letter-spacing: 2px;
    width: auto;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 18px;
  }
  .aptechka-bg-right-bottom {
    display: none;
  }
  .p-600-center {
    text-align: center;
  }
  .perenos-mobile {
    display: inline !important;
  }
  .znakomstvo-section .p-title {
    text-align: center;
  }
  .mobile-col-left {
    display: flex !important;
  }
  .form-section img {
    width: 100%;
  }
  .video-box {
    padding: 10px;
  }
}
@media(max-width: 480px) {
  .main-section {
    height: 100dvh;
  }
  .main-desc {
    display: none;
  }
  .main-mobile {
    display: block;
  }
  .main-text {
    position: relative;
    right: 0;
    width: 100%;
  }
  .h1-main {
    position: relative;
    text-align: center;
    right: 0;
  }
  .img-main {
    position: relative;
    margin-top: 30px;
    margin-bottom: 20px;
    right: 0;
    bottom: 0;
  }
  .btn-main-more {
    right: 0;
    bottom: 0;
    letter-spacing: 1.5px;
    position: relative;
  }
  .block-doctor {
    width: 100%;
    text-align: center;
  }
  .main-bg-doctor-avbox {
    left: 30%;
  }
  .video-box {
    padding: 0px;
  }
}
@media(max-width: 423px) {
  .avbox-bg-avbox-right.mobile-img {
    display: none;
  }
  .img-main {
    margin-top: 20px;
    margin-bottom: 15px;
    width: 180px;
  }
}
@media(max-width: 360px) {
  .emergency-img img {
    width: 100%;
  }
  .img-main {
    margin-bottom: 7px;
    width: 160px;
  }
}
</style>